import { createOrder, depWay, drawOrder } from "@/services/api/account";
import router from "@/router";
import KwaiqEvent from "@/utils/KwaiqEvent";
import store from '@/store';
import { userMoney } from "@/services/api/user";
import ConstConfig from "@/config/ConstConfig";
import { changeAmount } from "@/utils/CurrencyUtils";
import { Toast } from 'vant';

/**充值 */
export function toCharge(orderInfo) {
    if (orderInfo && orderInfo.amount) {
        let orderForm = {
            ...orderInfo,
        }
        createOrder(orderForm).then(res => {
            if (res.success == false) {
                Toast(res.msg);
                return;
            } else {
                KwaiqEvent.EventDoAddToCartKwaiq(changeAmount(orderForm.amount));
                let url = res.data.payUrl;
                let u = navigator.userAgent;
                let isiOS = !!u.match(/\(i[^;]+;( U;)? CPU.+Mac OS X/); //ios终端
                isiOS?(window.location.href = url) : window.open(url);
                // router.push("/home");
            }
        });
    } else {
        if (store.state.localeLanguage == "BR") {
            Toast("Por favor, insira a conta de saque que precisa ser vinculada");
        } else {
            Toast("Please enter the withdrawal account that needs to be linked");
        }
    }
}

/**提现 */
export function toWithDraw(amount) {
    let userAccount = localStorage.getItem(ConstConfig.LOCAL_CONST.USER_ACCOUNT);
    if (userAccount) {
        userAccount = JSON.parse(userAccount);
        let order = {
            userName: userAccount.userName,
            accountNumber: userAccount.accountNumber,
            phone: userAccount.phone,
            email: userAccount.email,
            payType: userAccount.payType,
            amount: amount,
            bankName: userAccount.bankName,
            branchBankName: userAccount.branchBankName
        } 
        drawOrder(order).then(res => {
            if (res.success == true) {
                localStorage.setItem(ConstConfig.LOCAL_CONST.WITHDRAW_LAST_ORDER, res.data.orderId);
                localStorage.setItem(ConstConfig.LOCAL_CONST.WITHDRAW_FAIL_FLAG, "1");
                userMoney({}).then(res => {
                    if (res.success) {
                        let userAccount = res.data;
                        if (userAccount) {
                            store.state.gold = userAccount.gold;
                        }
                    }
                });
                let gameConfig = localStorage.getItem(ConstConfig.LOCAL_CONST.GAME_CONFIG);
                if (gameConfig) {
                    gameConfig = JSON.parse(gameConfig);
                    if (store.state.localeLanguage == "BR") {
                        // Toast('A ordem de saque foi enviada ao banco, preste atenção às alterações da conta bancária');
                    } else {
                        // Toast('The withdrawal order has been sent to the bank, please pay attention to bank account changes');
                    }
                } else {
                    // Toast('The withdrawal order has been sent to the bank, please pay attention to bank account changes');
                }
                store.state.WithdrawPopups = 111;
            } else {
              Toast(res.msg);
            }
        });
    } else {
        if (store.state.localeLanguage == "BR") {
            Toast("Por favor, insira a conta de saque que precisa ser vinculada");
        } else {
            Toast("Please enter the withdrawal account that needs to be linked");
        }
    }
}
