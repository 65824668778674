import Vue from 'vue'
import Router from 'vue-router'
import store from '@/store';
import { exitIframe } from "@/handle/RefreshBalance";

Vue.use(Router)

// 重写router push方法，防止同路由跳转报错
const originalPush = Router.prototype.push
Router.prototype.push = function push(location) {
  return originalPush.call(this, location).catch(err => err)
}

const router = new Router({
  routes: [
    {
      path: '/',
      redirect: '/home'
    },
    // 首页
    {
      path: '/home',
      component: () => import('@/view/home'),
    },
    {
      path: '/security',
      component: () => import('@/view/security'),
      meta: {
        h5Layout: true,
      }
    },
    {
      path: '/drawcash',
      component: () => import('@/view/drawcash'),
      meta: {
        h5Layout: true,
      }
    },
    {
      path: '/event',
      component: () => import('@/view/event'),
      meta: {
        h5Layout: true,
      }
    },
    {
      path: '/event/item',
      component: () => import('@/view/eventItem'),
      meta: {
        h5Layout: true,
      }
    },
    {
      path: '/mine',
      component: () => import('@/view/mine'),
      meta: {
        h5Layout: true,
      }
    },
    {
      path: '/vip',
      component: () => import('@/view/vip'),
      meta: {
        h5Layout: true,
      }
    },
    // {
    //   path: '/viped',
    //   component: () => import('@/view/viped'),
    //   meta: {
    //     h5Layout: true,
    //   }
    // },
    {
      path: '/service',
      component: () => import('@/view/service'),
      meta: {
        h5Layout: true,
      }
    },
    {
      path: '/about',
      component: () => import('@/view/about'),
      meta: {
        h5Layout: true,
      }
    },
    {
      path: '/promote',
      component: () => import('@/view/promote'),
      meta: {
        h5Layout: true,
      }
    },
  ],
  linkActiveClass: 'active'
})

router.beforeEach((to, from, next) => {
  if(from.query.model == "Paymodel"){
    store.commit("setPayModalVisible", false);
  }
  window.addEventListener('popstate', ()=>{
    if(from.query.game == "Iframe"){
      exitIframe();
      store.state.gameUrl = "";
    }
  }, false);
  // 特定路由变化重新请求数据
  if(to.fullPath.indexOf("mine") != -1 || to.fullPath.indexOf("security") != -1 || to.fullPath == "/home"){
    store.commit("getRefreshBalance", true);
  }
  next();
});

export default router