export const menuList = [
    {
        title: "bottom.1",
        list: [
            {
                title: 'bottom.1_1',
                url: "/event/item?configKey=GREEN_VERSION_GREEN_TASK",
            },
            {
                title: 'bottom.1_2',
                url: "/event/item?configKey=SIGN_ACTIVITY",
            },
            {
                title: 'bottom.1_3',
                url: "/vip",
            },
            {
                title: 'bottom.1_4',
                url: "/promote",
            },
        ],
    },
    {
        title: "bottom.2",
        list: [
            {
                title: "bottom.2_1",
                url: "",
            },
            {
                title: "bottom.2_2",
                url: "",
            },
        ],
    },
    {
        title: "bottom.3",
        list: [
            {
                title: "bottom.3_1",
                url: "/service",
            },
        ],
    },
    {
        title: "bottom.4",
        url: "/about",
    },
    {
        title: "bottom.5",
    },
    {
        title: "",
        list: [
            {
                img: require('@/assets/18-plus.png')
            },
        ],
    },
]