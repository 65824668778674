<template>
  <a-modal
    :title="title"
    :visible="visible"
    :confirm-loading="confirmLoading"
    :footer="null"
    :maskClosable="false"
    centered
    :width="TipsType == 2 ? '300rem' : '340rem'"
    @cancel="$emit('handleOk', 'confirme')"
    :class="{ untips: TipsType == 2 }"
  >
    <div class="modal-wrap">
      <!-- VIP8  -->
      <div class="Wallet" v-if="TipsType == 2">
        <div class="text">{{ $t("limitPops.3") }}</div>
      </div>
      <!-- 未充值 PG类点击了[老虎、太阳] -->
      <div class="Wallet" v-if="TipsType == 1 || TipsType == 3">
        <div v-if="limitType == 1">
          <div class="text">{{ $t("limitPops.5") }}</div>
          <div class="button">
            <div type="primary" class="btn1" @click="playGame">
              {{ $t("limitPops.6") }}
            </div>
            <div type="primary" class="btn2" @click="limitType = 2">
              <span v-html="$t('limitPops.18')"></span>
            </div>
          </div>
        </div>
        <div v-else>
          <div class="text">{{ $t("limitPops.19") }}</div>
          <div class="button">
            <div type="primary" class="btn" @click="handleNext">
              {{ $t("limitPops.7") }}
            </div>
          </div>
        </div>
      </div>
      <!-- < V2 PG类点击了[老虎、太阳] -->
      <div class="Wallet" v-if="TipsType >= 4 && TipsType <= 10">
        <div class="text">
          <span v-if="TipsType == 4">{{
            $t("limitPops.9", {
              gameType: $store.state.gameType,
              vip:
                $store.state.gameType == "JILI"
                  ? $t("vip.2")
                  : $store.state.gameType == "PP"
                  ? $t("vip.3")
                  : $store.state.gameType == "MG"
                  ? $t("vip.4")
                  : $store.state.gameType == "FC" ||
                    $store.state.gameType == "JDB"
                  ? $t("vip.5")
                  : $store.state.gameType == "KA" ||
                    $store.state.gameType == "SBO"
                  ? $t("vip.6")
                  : $t("vip.7"),
            })
          }}</span>
          <span v-else-if="TipsType == 5">{{ $t("limitPops.12") }}</span>
          <span v-else-if="TipsType == 6">{{ $t("limitPops.13") }}</span>
          <span v-else-if="TipsType == 7">{{ $t("limitPops.14") }}</span>
          <span v-else-if="TipsType == 8">{{
            $t("limitPops.15", { gameType: $store.state.gameType })
          }}</span>
          <span v-else-if="TipsType == 9">{{
            $t("limitPops.16", { gameType: $store.state.gameType })
          }}</span>
          <span v-else-if="TipsType == 10">{{
            $t("limitPops.20", { gameType: $store.state.gameType })
          }}</span>
          <div class="button" v-if="TipsType == 4">
            <div type="primary" class="btn" @click="playGame">
              <span v-html="$t('limitPops.6')"></span>
            </div>
          </div>
          <div class="button" v-else>
            <div type="primary" class="btn1" @click="handleGame">
              {{ $t("limitPops.17") }}
            </div>
            <div type="primary" class="btn2" @click="handleNext">
              <span v-html="$t('limitPops.7')"></span>
            </div>
          </div>
        </div>
      </div>
      <!-- 未升级VIP8 v8解除所有游戏限制 -->
      <div class="Wallet" v-if="TipsType == 11">
        <div class="text">
          {{ $t("limitPops.10", { amount: $utils.getGold(30000) }) }}
        </div>
        <div class="button">
          <div type="primary" class="btn" @click="handleNext">
            <span v-html="$t('limitPops.11')"></span>
          </div>
        </div>
      </div>
    </div>
  </a-modal>
</template>

<script>
import ConstConfig from "@/config/ConstConfig";
import { getGameLink } from "@/handle/LoginHandle";
export default {
  props: {
    visible: {
      type: Boolean,
    },
    TipsType: {
      type: Number,
      default: 0,
    },
  },
  name: "limitPops",
  data() {
    return {
      confirmLoading: false,
      title: this.$t("limitPops.1"),
      ConstConfig,
      limitType: 1,
    };
  },
  created() {},
  methods: {
    handleNext() {
      switch (this.TipsType) {
        /** 未充值 需要充值 === 充值*/
        case 1:
          this.$emit("handleOk", "onCharge");
          break;
        /** 未充值 除Fortune OX ===  [充值/进入]游戏*/
        case 3:
          this.$emit("handleOk", "onCharge");
          break;
        case 4:
          this.$emit("handleOk", "onCharge");
          break;
        case 5:
          this.$emit("handleOk", "onCharge");
          break;
        case 6:
          this.$emit("handleOk", "onCharge");
          break;
        case 7:
          this.$emit("handleOk", "onCharge");
          break;
        case 8:
          this.$emit("handleOk", "onCharge");
          break;
        case 9:
          this.$emit("handleOk", "onCharge");
          break;
        case 10:
          this.$emit("handleOk", "onCharge");
          break;
        /** 未升级VIP8 v8解除所有游戏限制 ===  [充值]*/
        case 11:
          this.$store.state.payModalAmonut = 30000;
          this.$emit("handleOk", "onCharge");
          break;
      }
    },
    playGame() {
      this.$emit("handleOk", "confirme");
      getGameLink(66122);
    },
    handleGame() {
      this.$emit("handleOk", "PG");
    },
  },
};
</script>

<style lang="less" scoped>
@media only screen and (max-width: @screen-width4) {
  @import "./h5.less";
}
</style>
