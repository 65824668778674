import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import i18n from './i18n/index'
import Antd from 'ant-design-vue';
import moment from 'moment';
import VueLazyLoad from 'vue-lazyload'
import Vant from 'vant';
import { Locale } from 'vant';
import enUS from 'vant/lib/locale/lang/en-US';
import 'vant/lib/index.css';
import 'ant-design-vue/dist/antd.css';
import ChannelConfig from "@/utils/channelConfig";
import * as utils from "@/utils/utils";
import * as CurrencyUtils from "@/utils/CurrencyUtils";
import * as Functions from "@/utils/function";
import ConstConfig from './config/ConstConfig';
import { antologin } from "@/handle/LoginHandle";
import './registerServiceWorker'

Locale.use('en-US', enUS);
Vue.config.productionTip = false
Vue.prototype.$moment = moment
Vue.prototype.$utils = { ...CurrencyUtils,...utils,...Functions }

// 当前渠道配置参数
Vue.prototype.ChannelConfig = ChannelConfig[process.env.VUE_APP_CHANNEL || 'BR'] || {}

Vue.use(Antd);
Vue.use(Vant);

// 图片懒加载
Vue.use(VueLazyLoad, {
  // 加载中图片
  loading: require('@/assets/196.png'),
  // 加载失败图片
  error: require('@/assets/196.png'),
  // 等待时间
  throttleWait: 10
})

/**未获取到DEVICE_ID 自动登录*/ 
antologin();

let userLoginInfoStr = localStorage.getItem(ConstConfig.LOCAL_CONST.USER_INFO);
let userTellPassword = localStorage.getItem(ConstConfig.LOCAL_CONST.USER_TELL_PASSWORD);
let gameConfig = localStorage.getItem(ConstConfig.LOCAL_CONST.GAME_CONFIG);
let my_level = localStorage.getItem(ConstConfig.LOCAL_CONST.MY_LEVEL);
if (userLoginInfoStr && userLoginInfoStr != '') {
    try {
        let loginInfo = JSON.parse(userLoginInfoStr);
        store.state.isLogin = loginInfo.isLogin;
        store.state.nickName = loginInfo.nickName;
        store.state.headUrl = loginInfo.headUrl;
        store.state.userId = loginInfo.userId;
        store.state.token = loginInfo.token;
        store.state.userType = loginInfo.userType;
        if(my_level && my_level != ''){
          store.state.myLevel = parseInt(my_level);
        }
    } catch (e) {
        console.log(e);
    }
}

if (gameConfig && gameConfig != "") {
    try {
        let tempGameConfig = JSON.parse(gameConfig);
        store.state.localeLanguage = tempGameConfig.localeLanguage;
        store.state.currency = tempGameConfig.currency;
    } catch (e) {
        console.log(e);
    }
}

if (userTellPassword && userTellPassword != "") {
    try {
        let tempUserConfig = JSON.parse(userTellPassword);
        store.state.loginInfo = {
          ...tempUserConfig
        }
    } catch (e) {
        console.log(e);
    }
}



new Vue({
  i18n,
  router,
  store,
  render: h => h(App),
}).$mount('#app');

// setInterval(function() { check() }, 1000);
// var check = function() {
//     function doCheck(a) {
//         if (("" + a / a)["length"] !== 1 || a % 20 === 0) {
//             (function() {}["constructor"]("debugger")())
//         } else {
//             (function() {}["constructor"]("debugger")())
//         }
//         doCheck(++a)
//     }
//     try { doCheck(0) } catch (err) {}
// };
// check();


