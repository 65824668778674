module.exports = {
  // 公共
  'Common.payouts': '提现',
  'Common.deposit': '充值',
  'Common.all': '全部的',
  // Header
  'Header.login': '登录',
  'Header.registro': '记录',
  // BottomNav
  'BottomNav.home': '开始',  
  'BottomNav.promotion': '晋升',
  'BottomNav.inTheTank': '支持',
  'BottomNav.user-info': '轮廓',
  // Pay
  'Pay.deposit-online': '网上存款', 
  'Pay.deposit-money': '充值金额',
  'Pay.deposit-prompt': '使用条款：成功付款后，系统将在1到5分钟内将金额添加到您的帐户中', 
  'Pay.min': '最小限度', 
  'Pay.max': '最大限度',
  'Pay.deposit-submit': '立即充值',
  // PayModal
  'PayModal.deposit-log': '充值记录',
  'PayModal.deposit-verify-money': '请选择要充值的值',
  // LeftNav
  'LeftNav.popular': '流行的',
  'LeftNav.recently': '最近的',
  'LeftNav.favorites': '收藏夹',
  // More
  'More.game-more': '显示${total}款${name}游戏中的${current}款游戏', 
  'More.more': '加载更多',
  //Mine
  'Mine.recover': '恢复平衡',
  'Mine.millions-month': '每月百万',
  'Mine.account': '账户',
  // Promote
  'Promote.invite': '邀请',
  // Safety
  'Safety.security-center': '安全中心',
  // Notice
  'Notice.bonus': '建议奖金',
  'Notice.message-center': '留言中心',
  'Notice.message': '消息',
  'Notice.notice': '通知',
  'Notice.scroll-panel': '滚动面板',
  // About
  'About.about': '关于',
  // Setting
  'Setting.data': '数据',
  'Setting.back': '返回', 
  'Setting.save': '保存',  
  'Setting.day': '天', 
  'Setting.month': '月份',
  'Setting.year': '年', 
}