export default class ConstConfig {
    static  LOCAL_CONST = {
        /**玩家信息 */
        USER_INFO: "userInfo",
        /**账户信息 */
        USER_ACCOUNT: "userAccount",
        /**用户电话号码 + 密码 */
        USER_TELL_PASSWORD: "USER_TELL_PASSWORD",
        /**VIP等级 */
        MY_LEVEL: "myLevel",
        /**账号唯一id */
        DEVICE_ID: "DEVICE_ID",
        /** 游戏内嵌地址 */ 
        GAME_URL: "GAME_URL",
        /**账号创建时间 */
        CREATE_DAY: "CREATE_DAY",
        /**渠道相关信息 */
        APP_ID_INFO: "APP_ID_INFO",
        /**游戏相关配置 */
        GAME_CONFIG: "GAME_CONFIG",
        /**多语言 显示语言 US:英语 BR:葡语 */
        LOCALE_LANGUAGE: "LOCALE_LANGUAGE",
        /**VIP>=2 余额>=3000 游戏内出来 只弹出一次
         * 0：未弹出 1：已弹出 2：已关闭
        */
        TIPS_3000: "TIPS_3000",
        /**未登录提示弹窗  未获取到值/0未弹出  1已弹出*/ 
        UN_RESGISTER: "UN_RESGISTER",
        /**记录赢到过的最高金额*/ 
        GOLdEST: "GOLdEST",
        /**记录进游戏之前的金额*/ 
        GOLd_BEFORE: "GOLd_BEFORE",
        /**pix信息*/ 
        PIX_ACCOUNT: "PIX_ACCOUNT",
        /**提现失败标识 0:无需操作 1:有提现记录 2:提现失败 */
        WITHDRAW_FAIL_FLAG: "WITHDRAW_FLAG",
        /**最后一次提现的订单号 */
        WITHDRAW_LAST_ORDER: "WITHDRAW_LAST_ORDER",
    }

    /**语言配置 */
    static  LANGUAGE_CONFIG = {
        BR: "BR",   //葡语
        US: "US",   //英语
        ID: "ID"    //印尼语
    }

    /**货币配置 */
    static  CURRENCY_CONFIG = {
        /**巴西货币 */
        BRL: "BRL",
        /**巴西货币符号 */
        BRL_SIGN: "R$",
        /**尼日利亚货币 */
        NGN: "NGN",
        /**尼日利亚货币符号 */
        NGN_SIGN: "₦",
        /**印尼货币 */
        IDR: "IDR",
        /**印尼货币符号 */
        IDR_SIGN: "Rp",
    }

    /**
     * 金额配置
     * 数据内都以数组存储
     * 下标-0:巴西 1:尼日利亚 2:印尼
    */
    static  AMOUNT_CONFIG = {
        /**提现列表 */
        WITHDRAW_ARR: [
            [1000, 20000, 50000, 100000, 500000, 1000000],
            [1000, 50000, 100000, 500000, 1000000, 2000000],
            [1000, 50000, 100000, 500000, 1000000, 2000000]
        ],
        /**邀请奖励 */
        INVITATION_REWARDS: [600, 1500, 1500],
        /**新玩家赠送奖励 */
        NEW_PLAYER: [1000, 2000, 2000],
        /**新手任务奖励 */
        NEW_PLAYER_TASK_REWARDS: [3000, 12800, 12800],
        /**APK下载奖励 */
        APK_DOWNLOAD_REWARDS: [600, 1500, 1500],
        /**V8预热弹窗提示金额 */
        VIP_EIGHT_WINDOW: [300000, 1000000, 1000000],
        /**第一次提现限制金额 */
        FIRST_WITHDRAW_LIMIT: [1000, 1000, 1000],
        /**提现限制金额 */
        WITHDRAW_LIMIT: [1000, 1000, 1000],
        /**不同国家处理时间差异*/ 
        DIFFERENT_COUNTRY_TIME: [11 * 60 * 60 * 1000, 7 * 60 * 60 * 1000, 1 * 60 * 60 * 1000, 14 * 60 * 60 * 1000, 0, 1 * 60 * 60 * 1000, 1 * 60 * 60 * 1000, 5 * 60 * 60 * 1000],
    }

    /**充值对应编码类型 */
    static  BANK_CODE_CONFIG = {
        DOKUVA: "DOKUVA",
        QRIS: "QRIS",
        DANA: "DANA",
        OVO: "OVO",
        LinkAja: "LinkAja",
        SHOPEEPAY: "SHOPEEPAY",
    }
    
    /**订单对应类型 */
    static  ORDER_TYPE_CONFIG = {
        CPF: 1,
        BANK: 2,
        PHONE: 4,
        EMAIL: 5,
        WALLET: 6,
        QRCODE: 7
    }

    /**静态资源域名集 */
    static SOURCES_URL = [
        "https://d39aeoq8vaymye.cloudfront.net",
        "https://d2haz3kar9grut.cloudfront.net",
        "https://d1jmjspz6ydxov.cloudfront.net",
        "https://d3n2ips2wbho5h.cloudfront.net",
        "https://d2a2eavzb08rot.cloudfront.net"
    ]
}