import Vue from 'vue'
import VueI18n from 'vue-i18n'
import store from '@/store/index'
import ConstConfig from "@/config/ConstConfig";

const langCN = require('./lang/CN/KYCIndex')
const langUS = require('./lang/US/KYCIndex')
const langBR = require('./lang/BR/KYCIndex')

Vue.use(VueI18n)

const LOCALE_OPTIONS = [
  { label: '中文', value: 'CN' },
  { label: '美国', value: 'US' },
  { label: '巴西', value: 'BR' },
  { label: '印尼', value: 'ID' },
  { label: '印度', value: 'IN' },
  { label: '巴基斯坦', value: 'PK' },
  { label: '孟加拉', value: 'BG' },
  { label: '菲律宾', value: 'PH' },
];

const messages = {
  'CN': { ...langCN },
  'US': { ...langUS },
  'BR': { ...langBR }
}

let localLocale = "";
let gameConfig = localStorage.getItem(ConstConfig.LOCAL_CONST.GAME_CONFIG);
let country = process.env.VUE_APP_CHANNEL;
if (!gameConfig) {
    if (country == "BR") {
        localLocale = ConstConfig.LANGUAGE_CONFIG.BR;
    } else if(country == "US") {
        localLocale = ConstConfig.LANGUAGE_CONFIG.US;
    } else if (country == "ID") {
        localLocale = ConstConfig.LANGUAGE_CONFIG.ID;
    }
} else {
    gameConfig = JSON.parse(gameConfig);
    localLocale = gameConfig.localeLanguage;
}
store.state.localeLanguage = localLocale;

let locale = store.state.localeLanguage;

export default new VueI18n({
  locale,
  messages,
  fallbackLocale: 'BR',
  allowComposition: true,
  globalInjection: true,
})