<template>
  <div class="history-wrap">
    <Table
      :columns="columns"
      :source="source"
      :searchParam="searchParam"
      :tableType="tableType">
    </Table>
  </div>
</template>

<script>
import { rechargeRecord } from "@/services/api/account";
import Table from "@/components/Table";
export default {
  props: {},
  name: "History",
  components: {
    Table,
  },
  data() {
    return {
      columns: [
        {
          title: this.$t("History.1"),
          dataIndex: "CONTA",
          key: "CONTA",
          align: "left",
          scopedSlots: { customRender: "CONTA" },
        },
        {
          title: this.$t("History.2"),
          dataIndex: "TAXA",
          align: "center",
          scopedSlots: { customRender: "TAXA" },
        },
        {
          title: this.$t("History.3"),
          dataIndex: "DINHEIRO",
          align: "center",
        },
      ],
      source: [],
      formList: [
        {
          key: "value",
          type: "select",
          options: [
            {
              label: "Hoje",
              value: "Hoje",
            },
            {
              label: "Ontem",
              value: "Ontem",
            },
          ],
        },
      ],
      searchParam: {
        value: "Hoje",
      },
      tableType: 1,
    };
  },
  created() {
    this.getRechargeRecords();
  },
  methods: {
    /**获取充值记录*/
    getRechargeRecords() {
      let chargeForm = { id: this.$store.state.userId, pageNum: 1, pageSize: 10 };
      rechargeRecord(chargeForm).then((res) => {
        if (res.success) {
          let rechargeList = res.data.rechargeList;
          this.source = rechargeList.map((item, index) => {
            let dataItem = {};
            dataItem.key = index;
            dataItem.CONTA = item.recvAccount;
            dataItem.createTime = item.createTimeStr;
            dataItem.DINHEIRO = this.$utils.changeMoney(item.payAmount);
            if (item.state == 2) {
              dataItem.TAXA = "SUCCESSES";
              dataItem.state = 2;
            } else if (item.state == 3) {
              dataItem.TAXA = "FAILURES";
              dataItem.state = 3;
            } else {
              dataItem.TAXA = "TOWARD";
              dataItem.state = 1;
            }
            return dataItem;
          });
        }
      });
    },
  },
};
</script>

<style lang="less" scoped>
@import "./pc.less";
@media only screen and (max-width: @screen-width4) {
  @import "./h5.less";
}
</style>
