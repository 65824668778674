export default {
  // 印度
  IN: {
    currencyGold: '₹',
    currency: 'INR',
    areaCode: '+91'
  },
  // 巴西
  BR: {
    currencyGold: 'R$',
    currency: 'BRL',
    areaCode: '+55'
  },
  // 印尼
  ID: {
    currencyGold: 'RP',
    currency: 'IDR',
    areaCode: ''
  },
  // 巴基斯坦
  PK: {
    currencyGold: 'RS',
    currency: 'INR',
    areaCode: '+92'
  },
  // 孟加拉
  BG: {
    currencyGold: '৳',
    currency: 'BDT',
    areaCode: '+880'
  },
  // 菲律宾
  PH: {
    currencyGold: '₱',
    currency: 'PHP',
    areaCode: '+63'
  },
}