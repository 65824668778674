import { gameLink } from '@/services/api/game';
import { accountLogin,userBankInfo } from '@/services/api/user';
import ConstConfig from '@/config/ConstConfig';
import store from '@/store';
import FbEvent from '@/utils/FbEvent';
import KwaiqEvent from '@/utils/KwaiqEvent';
import { getRandom32 } from '@/utils/utils';
import { Toast } from 'vant';
import router from "@/router";

/**
 * 登录  邮箱登录loginType：6 apiType：2  电话号码登录loginType：2 apiType：2  账户注册loginType：5 apiType：1
 * 注册  游客(一键登录)loginType：1 apiType：2 账户注册loginType：5 apiType：2
 * */ 
export function autoRegistration(userInfo,loginType="1",apiType="2") {
  let channelId = getQueryString('tr') || getQueryString('zn') || getQueryString('zt') || getQueryString('mt') || getQueryString('mrt') || getQueryString('tui') || getQueryString('vcr') || getQueryString('cvt') || getQueryString('bni') || getQueryString('channel') || "";
  let inviteCode = getQueryString('code') || "";
  if (channelId) {
    channelId = channelId.replace(/\//, "");
  }
  const res = accountLogin({
    loginType,
    deviceId: loginType==1 ? getDeviceId() :"",
    userName: loginType == 5? userInfo.user : "",
    password: loginType == 5? userInfo.password : "",
    code: inviteCode,
    registChannel: channelId,
    apiType,
    fbc: FbEvent.getFbc(),
    fbp: FbEvent.getFbp(),
    fbEventUrl: FbEvent.getEventUrl(),
    fbClientUserAgent: FbEvent.getUserAgent()
  }).then(res => {
    if (res.success) {
      doLoginRes(res, userInfo);
    }else{
      Toast(res.msg);
    }
  });

};

function doLoginRes(res, userInfo) {
  if (res.success) {
    const b = res.data;
    store.state.isLogin = 1;
    store.state.nickName = b.nick;
    store.state.gold = b.gold;
    store.state.headUrl = b.headUrl;
    store.state.userId = b.userId;
    store.state.token = b.token;
    store.state.username = b.username;
    store.state.email = b.username;
    store.state.localeLanguage = b.language; //文字显示什么语言 en 英语  br葡语
    store.state.currency = b.currency;
    store.state.userType = b.utype; //获取哪一个客服链接
    initGameConfig();
    initUserInfo();
    userInfo && saveUserInfo(userInfo);
    let user_info = localStorage.getItem(ConstConfig.LOCAL_CONST.USER_ACCOUNT);
    if(!user_info || Object.keys(user_info).length == 0)  userBank();
    localStorage.setItem("externalId", b.externalId);
    localStorage.setItem(ConstConfig.LOCAL_CONST.CREATE_DAY, "" + new Date().getDate());
    //上报首次注册事件
    let eventId = "userId_" + b.userId;
    // FbEvent.EventRegistr(eventId);
    b.reportFbFlag && b.isRegister ? FbEvent.EventRegistr(eventId) : "";
    b.reportFbFlag && b.isRegister ? KwaiqEvent.EventRegistrKwaiq() : "";
    store.state.visible = b.isRegister ? 4 : 0;
    store.commit("getRefreshBalance", true);
  }
};

/**初始化游戏配置*/
function initGameConfig() {
  let gameConfig = {
    localeLanguage: store.state.localeLanguage,
    currency: store.state.currency,
  }
  localStorage.setItem(ConstConfig.LOCAL_CONST.GAME_CONFIG, JSON.stringify(gameConfig));
}

/**初始化用户信息*/
function initUserInfo() {
  let userMsg = {
    isLogin: 1,
    nickName: store.state.nickName,
    headUrl: store.state.headUrl,
    userId: store.state.userId,
    token: store.state.token,
    userType: store.state.userType
  };
  localStorage.setItem(ConstConfig.LOCAL_CONST.USER_INFO, JSON.stringify(userMsg));
}

/**保存用户账户+密码*/ 
export function saveUserInfo(Data) {
  let userInfo = {
    ...Data,
  };
  store.commit("setUserInfo", userInfo);
  localStorage.setItem(ConstConfig.LOCAL_CONST.USER_TELL_PASSWORD, JSON.stringify(userInfo));
}

/**获取用户银行卡信息*/
function userBank() {
  userBankInfo({}).then((res) => {
    if (res.success && res.data.bankInfo) {
      let bankInfo = res.data.bankInfo;

      let drawForm = {
        userName: bankInfo.userName,
        accountNumber: bankInfo.accountNumber,
        accountNumberCPF: bankInfo.accountNumber,
        phone: bankInfo.phoneNumber,
        email: bankInfo.email,
        payType: bankInfo.type,
        bankName: bankInfo.bankName,
        branchBankName: bankInfo.branchBankName,
      }

      localStorage.setItem(
        ConstConfig.LOCAL_CONST.USER_ACCOUNT,
        JSON.stringify(drawForm)
      );
    }
  });
}

export function getGameLink(gameId) {
  //判断用户使用的浏览器
  let userAgent = navigator.userAgent;
  // 用户使用手机
  let platform = navigator.platform;
  gameLink({ id: gameId }).then(res => {
    if (res.success == false) {
      return;
    } else {
      let url = res.data.openGameUrl;
      //window.location.href = url;
      store.state.gameUrl = url;
      localStorage.setItem(ConstConfig.LOCAL_CONST.GAME_URL, url);
      localStorage.setItem(ConstConfig.LOCAL_CONST.GOLd_BEFORE, store.state.gold);
    }
  });
}

//获取设备id
function getDeviceId() {
  let deviceId = localStorage.getItem(ConstConfig.LOCAL_CONST.DEVICE_ID);
  if (deviceId) {
    return deviceId;
  } else {
    deviceId = getRandom32();
    localStorage.setItem(ConstConfig.LOCAL_CONST.DEVICE_ID, deviceId);
  }
  return deviceId;
}

export function  getQueryString(name) {
  try{
  let search=window.location.search;
  
  if(!search){
     let href=window.location.href;
     search= href.substr(href.indexOf("?"));
  }
 var reg = new RegExp("(^|&)" + name + "=([^&]*)(&|$)", "i");
 var r = search.substr(1).match(reg);
 
 if (r != null){
   let value= unescape(r[2]);
   if(value.indexOf("#")>=value.length-3){
     value=value.split("#")[0];
   }
   return value;
 } 
 }catch(e){
  console.error(e);
 }
 return null;
}

/**退出登录*/ 
export function loginOut() {
  localStorage.removeItem(ConstConfig.LOCAL_CONST.USER_INFO);
  localStorage.removeItem(ConstConfig.LOCAL_CONST.GOLdEST);
  localStorage.removeItem(ConstConfig.LOCAL_CONST.USER_ACCOUNT);
  localStorage.removeItem(ConstConfig.LOCAL_CONST.MY_LEVEL);
  localStorage.removeItem(ConstConfig.LOCAL_CONST.PIX_ACCOUNT);
  store.state.isLogin = 0;
  store.state.nickName = "";
  store.state.userId = "";
  store.state.token = "";
  store.state.userType = "";
  store.state.gold = 0;
  store.state.myLevel = 0;

  router.push("/");
  setTimeout(function () {
    location.reload();
  }, 100);
}

/**检测未绑定账户 自动登录*/ 
export function antologin() {
  let DEVICE_ID = localStorage.getItem(ConstConfig.LOCAL_CONST.DEVICE_ID);
  if (!DEVICE_ID) {
    store.dispatch("handleCheckLogin");
  }
}
