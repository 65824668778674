import { accDiv, accMul } from "./utils";
import store from '@/store'
import ConstConfig from "@/config/ConstConfig"

/**通过货币代码获取货币符号 */
export function convertCurrency() {
  if (getCurrency() == ConstConfig.CURRENCY_CONFIG.BRL) {
      return ConstConfig.CURRENCY_CONFIG.BRL_SIGN;
  } else if (getCurrency() == ConstConfig.CURRENCY_CONFIG.NGN) {
      return ConstConfig.CURRENCY_CONFIG.NGN_SIGN;
  } else if (getCurrency() == ConstConfig.CURRENCY_CONFIG.IDR) {
    return ConstConfig.CURRENCY_CONFIG.IDR_SIGN;
  }
  return "";
}

/** 
 * 转换金额
 * @param gold: 金额,以分为单位
*/
export function changeMoney (gold) {
  if (getCurrency() == ConstConfig.CURRENCY_CONFIG.BRL) {  //巴西货币(雷尔)
    return changeMoneyByBRL(gold);
  } else if (getCurrency() == ConstConfig.CURRENCY_CONFIG.NGN) { //尼日利亚(奈拉)
    return changeMoneyByNGN(gold);
  } else if (getCurrency() == ConstConfig.CURRENCY_CONFIG.IDR) {  //印尼(卢比)
    return changeMoneyByID(gold);
  }
  return "";
};

/**获取带单位的金额 */
export function getGold(gold) {
    let tempGold = convertCurrency();
    if (tempGold) {
      tempGold = tempGold + changeMoney(gold);
    } else {
      return "";
    }
    return tempGold;
}

/**
 * 获取转换后的金额 不带符号
 * */
export function changeAmount(amount) {
  if (getCurrency() == ConstConfig.CURRENCY_CONFIG.BRL) {  //巴西货币(雷尔)
    return accDiv(amount, 100);
  } else if (getCurrency() == ConstConfig.CURRENCY_CONFIG.NGN) { //尼日利亚(奈拉)
    return amount;
  } else if (getCurrency() == ConstConfig.CURRENCY_CONFIG.IDR) { //印尼(卢比)
    return amount;
  }
  return 0;
}

/**获取金额配置的下标 */
export function getAmountIndex() {
  if (getCurrency() == ConstConfig.CURRENCY_CONFIG.BRL) {
      return 0;
  } else if (getCurrency() == ConstConfig.CURRENCY_CONFIG.NGN) {
      return 1;
  } else if (getCurrency() == ConstConfig.CURRENCY_CONFIG.IDR) {
    return 2;
  }
  return 0;
}

/**获取当前货币型号 */
function getCurrency() {
  if (store.state.currency) {
    return store.state.currency;
  } else {
    let gameConfig = localStorage.getItem(ConstConfig.LOCAL_CONST.GAME_CONFIG);
    if (gameConfig && gameConfig.currency) {
      return gameConfig.currency;
    }
  }
  return "";
}

/**转换货币 巴西 雷亚尔 */
function changeMoneyByBRL(gold) {
  let tempMoney = ""+gold;
  tempMoney = accDiv(gold, 100).toFixed(2);
  tempMoney = tempMoney.replace(".", ",");
  return tempMoney.replace(/\d+/, function (m) {
    return m.replace(/(?=(\B)(\d{3})+$)/g, ".");
  });
}

/**转换货币 尼日利亚 奈拉*/
function changeMoneyByNGN(gold) {
  let tempMoney = ""+gold;
  return tempMoney.replace(/\d+/, function (m) {
    return m.replace(/(?=(\B)(\d{3})+$)/g, ",");
  });
}

/**转换货币 印尼 卢比*/
function changeMoneyByID(gold) {
  let tempMoney = ""+accMul(gold, 10);
  let m = tempMoney.replace(/\d+/, function (m) {
    return m.replace(/(?=(\B)(\d{3})+$)/g, ".");
  });
  if (Number(tempMoney) >= 100000000) {
    m = m.substring(0, m.length - 8) + ",00jt";
  } else if (Number(tempMoney) >= 1000000) {
    //rb
    m = m.substring(0, m.length - 4) + ",00k";
  }
  return m;
}


export function AddH5() {
  const H5AddClassName = "App-H5";
    const appNode = document.getElementById("app");
    appNode.classList.add(H5AddClassName);
}

export default {
    convertCurrency,
    changeMoney,
    getGold,
    getAmountIndex,
    AddH5,
}