import axios from 'axios';
import store from '@/store/index';
import { loginOut } from "@/handle/LoginHandle";

const BASE_URL = process.env.VUE_APP_BASEURL;

// 跨域认证信息 header 名
// const xsrfHeaderName = 'Authorization'
axios.defaults.timeout = 5000
// axios.defaults.withCredentials = true
// axios.defaults.xsrfHeaderName = xsrfHeaderName
// axios.defaults.xsrfCookieName = xsrfHeaderName

// 创建axios实例
const service = axios.create({
  baseURL: BASE_URL, // api的base_url
  // timeout: 5000 // 请求超时时间
});

// 请求拦截器
service.interceptors.request.use(
  config => {
    if (config.url === '') {
      config.headers = {  // 登录 --- 需清空Authorization字段

      };
    } else {
      config.headers = {
        'Content-Type': 'application/json',
        // const token = store.state.token,
        'access-token': store.state.token,
      }
    }
    // 可以在这里添加请求头等信息
    return config;
  },
  error => {
    // 请求错误处理
    console.log(error); // for debug
    Promise.reject(error);
  }
);

// 响应拦截器
service.interceptors.response.use(
  response => {
    store.state.spinning = false;
    // 对响应数据做处理，例如只返回data部分
    const res = response.data;
    // 根据返回的状态码做相应处理，例如401未授权等
    if(!res.success && res.msgCode == 'token_invalid'){
      loginOut();
    }else{
      return res;
    }
  },
  error => {
    store.state.spinning = false;
    // 响应错误处理
    console.log('err' + error); // for debug
    return Promise.reject(error);
  }
);

/* 处理正确返回的数据 */
export const checkStatus = res => {
  return res
  // const data = res.data
  // const status = data.status
  // if (status === 0) {
  //   return data;
  // } else {
  //   if ((res.config && res.config.responseType) !== 'blob') {
  //     message.error(data.msg);
  //   }
  //   if (status === 401) {
  //     window.vm.$router.push('/login')
  //     removeAuthorization()
  //   }
  //   return data;
}


async function request(url, method, params, config) {
  switch (method) {
    case 'GET':
      return service.get(url, {
        params,
        ...config
      }).then(checkStatus)
    case 'POST':
      if(url.indexOf("accountInfo") == -1){
        store.state.spinning = true;
      }
      return service.post(url, params, config).then(checkStatus)
    case 'PUT':
      return service.put(url, params, config).then(checkStatus)
    case 'DELETE':
      return service.delete(url, params, config).then(checkStatus)
    default:
      return service.get(url, {
        params,
        ...config
      }).then(checkStatus)
  }
}

export {
  request
}