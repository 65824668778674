<template>
  <div class="pay-wrap">
    <div class="modal-box">
      <div class="title-wrap">
        <span class="title">{{ $t("Pay.deposit-online") }}</span>
      </div>
      <div class="card-wrap">
        <div v-for="(item, i) in payList" :key="i" class="item">
          <div class="item-active" :class="{ active: i === payActive }" @click="selectChannel(i)">
            <img :src="item.icon" />
            {{ item.name }}
          </div>
        </div>
      </div>
    </div>

    <div class="list-box">
      <div>
        <span class="title">{{ $t("Pay.deposit-money") }}</span>
      </div>
      <div class="list-wrap">
        <div v-for="item in moneyList" :key="item.gold" class="item">
          <div class="item-active" :class="{ active: item.gold === moneyActive }" @click="selectedMoney(item)">
            <span>{{ $utils.changeMoney(item.gold) }}</span>
            <div class="insideCard" v-if="item.firstRate && item.firstRate != 0">
              +{{ item.firstRate * 100 }}%
            </div>
            <div class="insideCard" v-if="item.extraRate != 0 && item.extraRate">
              +{{ item.realRate * 100 }}%
            </div>
          </div>
        </div>
      </div>
      <a-input
        allowClear
        class="money-input"
        size="large"
        :prefix="ChannelConfig.currencyGold"
        v-model="showMoney"
        disabled>
        <template #addonAfter v-if="showMoney">
          {{ $t("Pay.Extra") }} +{{ ChannelConfig.currencyGold }}{{ giveMoney }}
        </template>
      </a-input>
    </div>

    <div class="prompt">
      {{ $t("Pay.deposit-prompt") }}
    </div>

    <div class="btn-wrap">
      <a-button class="btn" size="large" type="primary" @click="handleSubmit">
        {{ $t("Pay.deposit-submit") }}
      </a-button>
    </div>
  </div>
</template>

<script>
import { depWay } from "@/services/api/account";
import { toCharge } from "@/handle/ChargeHandle";
export default {
  props: {},
  name: "Pay",
  data() {
    return {
      payActive: 0,
      payList: [],
      moneyActive: "",
      showMoney: "",
      moneyList: [],
      giveMoney: "",
      orderForm: {
        //充值订单数据
        itemId: 0,
        channelId: 0,
        amount: 0,
      },
    };
  },
  created() {
    this.getChannel();
  },
  // activated() {
  //   this.toVIP();
  // },
  methods: {
    handleSubmit() {
      if (this.moneyActive === "") {
        this.$toast("Por favor, escolha o valor que desejarecarregar");
        return;
      }
      toCharge(this.orderForm);
      this.$emit("onCancel", "onCharge");
    },
    /**获取充值列表*/
    getChannel() {
      depWay({}).then((res) => {
        if (res.success) {
          this.payList = res.data.list;
          this.moneyList = this.payList[0].items;
          this.classify(this.moneyList[0]);
        }
      });
    },
    /***选中金额*/
    selectedMoney(data) {
      this.$store.state.payModalAmonut = 0;
      this.classify(data);
    },
    /***切换渠道*/
    selectChannel(index) {
      this.payActive = index;
      this.moneyList = this.payList[index].items;
      this.classify(this.moneyList[0]);
    },
    /**公共处理*/
    classify(data) {
      let newData = { ...data };
      this.moneyActive = newData.gold;
      if (this.$store.state.payModalAmonut) {
        this.moneyActive = this.$store.state.payModalAmonut;
        for (let i = 0; i < this.moneyList.length; i++) {
          let payItem = this.moneyList[i];
          if (this.moneyActive == payItem.gold) {
            newData.id = payItem.id;
            newData.channelId = payItem.channelId;
            newData.firstRate = payItem.firstRate;
          }
        }
        newData.gold = this.moneyActive;
      }
      this.showMoney = this.$utils.changeMoney(this.moneyActive);
      let tempGiveMoney = this.$utils.accMul(
        this.moneyActive,
        newData.firstRate
      );
      this.giveMoney = this.$utils.changeMoney(tempGiveMoney);
      this.orderForm = {
        itemId: newData.id,
        channelId: newData.channelId,
        amount: newData.gold,
      };
    },
    /**判断VIP等级
     * v0-v1 30
     * v1-v2 100
     * v2-v3 300
     * v7-v8 300
     * */
    toVIP() {
      let vip = this.$store.state.myLevel;
      if (!this.$store.state.payModalAmonut) {
        switch (vip) {
          case 0:
            this.$store.state.payModalAmonut = 3000;
            break;
          case 1:
            this.$store.state.payModalAmonut = 10000;
            break;
          case 2:
            this.$store.state.payModalAmonut = 30000;
            break;
          case 7:
            this.$store.state.payModalAmonut = 30000;
            break;
        }
      }
    },
  },
};
</script>

<style lang="less" scoped>
@import "./pc.less";
@media only screen and (max-width: @screen-width4) {
  @import "./h5.less";
}
</style>
