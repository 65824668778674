<template>
  <div class="login-check-wrap">
    <a-modal
      :visible="visible"
      :confirm-loading="confirmLoading"
      :footer="null"
      :closable="false"
      :maskClosable="false"
      width="320rem"
      centered
      @ok="handleOk"
      @cancel="handleCancel">
      <!-- 注册 -->
      <div v-if="isLoginType == 2" class="register">
        <div class="title-wrap">
          <div class="title">
            <img src="@/assets/login/user.png" class="user" />
            <span>{{ $t("login.1") }}</span>
          </div>
        </div>
        <a-form
          :form="form"
          :label-col="{ span: 5 }"
          :wrapper-col="{ span: 16 }"
          @submit="handleSubmit">
          <!-- 账号 手机号/邮箱 -->
          <a-form-item>
            <a-input
              :placeholder="$t('login.2')"
              v-decorator="[
                'validateLoginUser',
                validatorRules.validateLoginUser,
              ]"
              class="userAccount">
              <a-icon slot="prefix" type="user" />
              <span slot="prefix">+55</span>
            </a-input>
          </a-form-item>
          <!-- 密码 -->
          <a-form-item>
            <a-input-password
              :placeholder="$t('login.3')"
              autocomplete="off"
              v-decorator="[
                'validatePassword',
                validatorRules.validatePassword,
              ]">
              <a-icon slot="prefix" type="lock" />
            </a-input-password>
          </a-form-item>
          <!-- 确认密码 -->
          <a-form-item>
            <a-input-password
              :placeholder="$t('login.4')"
              autocomplete="off"
              v-decorator="[
                'validateComPassword',
                validatorRules.validateComPassword,
              ]">
              <a-icon slot="prefix" type="lock" />
            </a-input-password>
          </a-form-item>
          <!-- 我已经满18岁 -->
          <!-- <div class="ages">
            <a-checkbox @change="onChange">
              Tenho 18 anos, li e concordo com
            </a-checkbox>
          </div> -->
          <!-- 确定登录 注册 -->
          <div class="btn-wrap">
            <a-form-item>
              <a-button html-type="submit" class="btn" type="primary">{{
                $t("login.7")
              }}</a-button>
            </a-form-item>
          </div>
          <!-- 切换登录 -->
          <div class="changeLogin" @click="changeLogin(1)">
            {{ $t("login.5") }}
          </div>
        </a-form>
      </div>
      <!-- 登录 -->
      <div v-if="isLoginType == 1" class="login">
        <div class="title-wrap">
          <div class="title">
            <img src="@/assets/login/user.png" class="user" />
            <span>{{ $t("login.9") }}</span>
          </div>
        </div>
        <a-form
          :form="form"
          :label-col="{ span: 5 }"
          :wrapper-col="{ span: 16 }"
          @submit="handleSubmit">
          <!-- 账号 手机号/邮箱 -->
          <a-form-item>
            <a-input
              :placeholder="$t('login.2')"
              v-decorator="[
                'validateLoginUser',
                validatorRules.validateLoginUser,
              ]"
              class="userAccount">
              <a-icon slot="prefix" type="user" />
              <span slot="prefix">+55</span>
            </a-input>
          </a-form-item>
          <!-- 密码 -->
          <a-form-item>
            <a-input-password
              :placeholder="$t('login.3')"
              autocomplete="off"
              v-decorator="[
                'validatePassword',
                validatorRules.validatePassword,
              ]">
              <a-icon slot="prefix" type="lock" />
            </a-input-password>
          </a-form-item>
          <!-- 确定登录 注册 -->
          <div class="btn-wrap">
            <a-form-item>
              <a-button html-type="submit" class="btn" type="primary">{{
                $t("login.8")
              }}</a-button>
            </a-form-item>
          </div>
          <!-- 切换登录 -->
          <div class="changeLogin" @click="changeLogin(2)">
            {{ $t("login.6") }}
          </div>
        </a-form>
      </div>

      <!-- 关闭按钮 -->
      <img src="@/assets/login/close.png" class="close" @click="handleCancel" />
    </a-modal>
  </div>
</template>

<script>
import { autoRegistration } from "@/handle/LoginHandle";
export default {
  props: {},
  name: "LoginCheck",
  data() {
    return {
      form: this.$form.createForm(this, { name: "coordinated" }),
      confirmLoading: false,
      /**2：注册 1：登录*/
      // isLoginType: 2,
      value: false,
      validatorRules: {
        validateLoginUser: {
          //name与v-decorator中属性对应
          // initialValue: "", //初始值
          rules: [
            { required: true, message: "format error" }, //此处开启校验必填
            { validator: this.LoginUser }, //校验规则函数，
          ],
          trigger: "input", // 触发方式
        },
        validatePassword: {
          //name与v-decorator中属性对应
          // initialValue: "", //初始值
          rules: [
            { required: true, message: "format error" }, //此处开启校验必填
            { validator: this.Password }, //校验规则函数，
          ],
          trigger: "input", // 触发方式
        },
        validateComPassword: {
          //name与v-decorator中属性对应
          // initialValue: "", //初始值
          rules: [
            { required: true, message: "format error" }, //此处开启校验必填
            { validator: this.ComPassword }, //校验规则函数，
          ],
          trigger: "input", // 触发方式
        },
      },
      loginfo: {
        user: "",
        password: "",
      },
    };
  },
  computed: {
    visible: {
      get() {
        return this.$store.state.loginShow;
      },
      set(e) {
        this.$store.commit("handleLoginVisible", e);
      },
    },
    isLoginType() {
      return this.$store.state.loginType;
    },
  },
  watch: {
    visible(val) {
      if (val == true) {
        this.defaultData();
      }
    },
  },
  beforeCreate() {
    this.form = this.$form.createForm(this, { name: "register" });
  },
  mounted() {
    // this.visible &&
    setTimeout(() => {
      this.defaultData();
    }, 100);
  },
  methods: {
    /**退出登录获取绑定信息 渲染默认显示*/
    defaultData() {
      if (this.$store.state.loginInfo.user && this.visible) {
        let userInfo = { ...this.$store.state.loginInfo };
        this.$nextTick(() => {
          this.form.setFieldsValue({
            validateLoginUser: userInfo.user,
          });
          this.form.setFieldsValue({
            validatePassword: userInfo.password,
          });
        });
      }
    },
    handleOk() {
      this.form.validateFields((errors, values) => {
        if (!errors) {
          this.confirmLoading = true;
          setTimeout(() => {
            this.$store.commit("setUserInfo", values);
            this.$store.state.loginCallBack && this.$store.state.loginCallBack();
            this.$store.commit("setLoginCallBack", null);
            this.visible = false;
            this.confirmLoading = false;
          }, 1000);
        }
      });
    },
    /**关闭弹窗*/
    handleCancel() {
      this.visible = false;
    },
    onChange(e) {
      this.value = e.target.checked;
    },
    /**切换登录方式*/
    changeLogin(type) {
      this.$store.state.loginType = type;
    },
    /**验证账户登录格式*/
    LoginUser(rule, value, callback) {
      if (value) {
        this.loginfo.user = value;
        this.form.setFieldsValue({
          validateLoginUser: value.toString(),
        });
        let format = this.EmailVerify(value);
        if (format) {
          //验证邮箱
          return callback();
        } else if (!isNaN(value) && value.length >= 10 && value.length <= 12) {
          //验证电话号码
          return callback();
        }
        this.loginAccontType = 0;
        return callback(new Error("format error"));
      }
      callback();
    },
    /** 邮箱验证 **/
    EmailVerify(data) {
      let state =
        data.includes("@gmail.com") ||
        data.includes("@yahoo.co.in") ||
        data.includes("@yahoo.com") ||
        data.includes("email.com") ||
        data.includes("@rediffmail.com") ||
        data.includes("@hotmail.com") ||
        data.includes("@ymail.com") ||
        data.includes("@outlook.com") ||
        data.includes("@msn.com") ||
        data.includes("@icloud.com");
      return state;
    },
    /**注册输入密码*/
    Password(rule, value, callback) {
      // console.log("Password", value);
      if (value && value.length < 6) {
        return callback(new Error("format error"));
      }
      this.loginfo.password = value;
      this.form.setFieldsValue({
        validatePassword: value,
      });
      return callback();
    },
    /**注册输入确认密码*/
    ComPassword(rule, value, callback) {
      // console.log("ComPassword", value);
      if (value && this.loginfo.password != value) {
        return callback(new Error("format error"));
      }
      this.form.setFieldsValue({
        validateComPassword: value,
      });
      return callback();
    },
    /**登录*/
    handleSubmit(e) {
      e.preventDefault();
      this.form.validateFieldsAndScroll((err, values) => {
        if (!err) {
          this.loginAccount();
        }
      });
    },
    loginAccount() {
      autoRegistration(this.loginfo, 5, this.isLoginType);
      this.handleCancel();
    },
  },
};
</script>

<style lang="less" scoped>
// @import "./pc.less";
@media only screen and (max-width: @screen-width4) {
  @import "./h5.less";
}
</style>
