<template>
  <div class="table-wrap">
    <div class="table-header" v-if="formList || $slots['header-right']">
      <!-- 表格筛选项 -->
      <div v-if="formList" class="header-flex">
        <template v-for="(item, i) in formList">
          <a-select
            v-model="param[item.key]"
            :options="item.options"
            :placeholder="item.placeholder"
            class="sel"
            :key="i"
            v-if="item.type === 'select'"
            @change="searchChange"
          />
          <a-input-search
            v-model="param[item.key]"
            :placeholder="item.placeholder"
            class="header-search"
            @search="onSearch"
            v-if="item.type === 'input'"
            :key="i"
          />
          <div v-if="item.type === 'date'" :key="i" class="date-show-box">
            <div class="date-item" @click="handleData(0, item)">
              {{ dateParam[range[0]] || $moment().format("DD/MM/YYYY") }}
            </div>
            <span>-</span>
            <div class="date-item" @click="handleData(1, item)">
              {{ dateParam[range[1]] || $moment().format("DD/MM/YYYY") }}
            </div>
          </div>
        </template>
      </div>
      <!-- 顶部右侧插槽 -->
      <slot name="header-right"></slot>
    </div>
    <van-popup v-model="dateShow" position="bottom">
      <van-datetime-picker
        v-model="dateValue"
        type="date"
        @confirm="dateConfirm"
        @cancel="dateShow = false"/>
    </van-popup>
    <a-table
      :columns="columns"
      :data-source="source"
      :pagination="pagination"
      @change="handleTableChange"
      rowKey="key">
      <span slot="CONTA" slot-scope="text, record" v-if="tableType == 1 || tableType == 2">
        <span>{{ record.CONTA }}</span><br />
        <span style="white-space: nowrap; overflow: hidden; display: inline-block; width: 125rem;">{{ record.createTime }}</span>
      </span>
      <span slot="TAXA" slot-scope="text, record" v-if="tableType == 1 || tableType == 2">
        <span
          :class="{
            orange: record.state == 1,
            green: record.state == 2,
            gray: record.state == 3,
          }">{{ record.TAXA }}</span>
      </span>
    </a-table>
  </div>
</template>

<script>
export default {
  props: {
    // 表头参数
    columns: {
      type: Array,
    },
    // 表格数据
    source: {
      type: Array,
    },
    // 筛选项表单
    formList: {
      type: Array,
    },
    // 默认筛选参数
    searchParam: {
      type: Object,
    },
    // 是否分页
    pagination: {
      type: Object,
      default: false,
    },
    /**展示表格类型 1:充值记录 2:提现记录*/
    tableType: {
      type: Number,
      default: 0,
    },
  },
  name: "Table",
  data() {
    return {
      dateShow: false,
      param: { ...this.searchParam },
      dateParam: {},
      dateValue: new Date(),
      range: ["", ""],
      active: 0,
    };
  },
  methods: {
    handleData(i, item) {
      this.active = i;
      this.range[i] = item.range[i];
      this.dateShow = true;
    },
    dateConfirm(value) {
      this.dateParam[this.range[this.active]] =
        this.$moment(value).format("DD/MM/YYYY");
      this.dateShow = false;
    },
    searchChange() {
      console.log(this.param);
    },
    onSearch(val) {
      console.log(val);
    },
    handleTableChange(pag) {
      this.$emit('change', pag);
    }
  },
};
</script>

<style lang="less" scoped>
@import "./pc.less";
@media only screen and (max-width: @screen-width4) {
  @import "./h5.less";
}
</style>
