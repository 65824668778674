/*
 * @Author: stingyxin 894739825@qq.com
 * @Date: 2023-05-24 15:32:00
 * @LastEditors: stingyxin 894739825@qq.com
 * @LastEditTime: 2023-06-21 11:23:58
 * @FilePath: \testgamehall\assets\script\webSDK\facebook\FbEvent.ts
 * @Description: 这是默认设置,请设置`customMade`, 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
 */
/**
 * 数数 打点统计
 * 更多接口参照《ThinkingAnalyticsAPI》类 ,外部不能直接调用,
 * 必须通过ThinkingEvent._ta 调用
 */

// import { Environment } from "../../Environment";
// import { ChannelUtil } from "../../channel/ChannelUtil";
// import { SlotsUtils } from "../../logic/game/gameBase/slotsGame/SlotsUtils";

import Vue from "vue"

var vm = new Vue()

/**初始化sdk */
export function init(debug) {

}

/**获得快手事件 */
function getKwaiqEvent() {
    return (window).kwaiq;
}

/**
 * 上报事件
 * @param eventName 事件名称
 * @param agrs 事件参数
 * @returns 
 */
function track(eventName, agrs) {
    let _kwaiqEvent = getKwaiqEvent();
    if (!_kwaiqEvent || !_kwaiqEvent.ccKwaiqId) {
        return;
    }
    if (agrs) {
        _kwaiqEvent.instance(_kwaiqEvent.ccKwaiqId).track(eventName, agrs);
    } else {
        _kwaiqEvent.instance(_kwaiqEvent.ccKwaiqId).track(eventName);
    }
}

/**注册事件 */
export function EventRegistrKwaiq() {
    track('completeRegistration');
}

/**充值事件 */
export function EventDoChargeKwaiq(value) {
    // let goldNum = SlotsUtils.convert(value);
    let goldNum = parseInt(parseInt(value) / 100);
    let args = {
        value: goldNum,
        currency: vm.ChannelConfig.currency
    };
    track('purchase', args);
}

/**首充事件 */
export function EventFirstChargeKwaiq(value) {
    let goldNum = parseInt(parseInt(value) / 100);
    let args = {
        value: goldNum,
        currency: vm.ChannelConfig.currency
    };
    track('firstDeposit', args);
}
/**加购事件 */
export function EventDoAddToCartKwaiq(value) {
    let goldNum = parseInt(parseInt(value) / 100);
    let args = {
        value: goldNum,
        currency: vm.ChannelConfig.currency
    };
    track('addToCart', args);
}

/**获取访问参数 */
function getQueryString(name) {
    let href = window.location.href;
    let search = href.substr(href.indexOf("?"));
    if (!search) {
        search = window.location.search;
    }
    let reg = new RegExp("(^|&)" + name + "=([^&]*)(&|$)", "i");
    let rs = search.substr(1);
    let r = rs.match(reg);
    if (r != null) {
        return unescape(r[2]);
    }
    return null;
}
/**获得快手像素事件的附加参数
 * 请注意：
 * 对于广告点击后用户会话内发生的页面跳转，请确保click_id与pixel_id从原始落地页传递到后续跳转到的页面。将用户定向到后续页面时，您需要将click_id与pixel_id附在后续页面的末尾。
 */
export function getKwaiqAddtionParam(url) {
    let click_id = getQueryString("click_id");
    let pixel_id = getQueryString("pixel_id");
    let addParam = "";
    if (click_id && pixel_id) {
        addParam = `click_id=${click_id}&pixel_id=${pixel_id}`;
        if (url.indexOf('?') == -1) {
            url = url + "?" + addParam;
        } else {
            url = url + "&" + addParam;
        }
    }
    return url;
}

export default {
    EventRegistrKwaiq,
    EventDoChargeKwaiq,
    EventFirstChargeKwaiq,
    EventDoAddToCartKwaiq,
    getKwaiqAddtionParam,
}
