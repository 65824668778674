import {
  request
} from '../request'

/** 获取活动配置 */
export const getActiveData = (param) => request(`/activity/getActivityConfig`, 'POST', param);

/**充值渠道查询 */
export const depWay = (param) => request(`/charge/channel/list`, 'POST', param);

/**创建充值订单 */
export const createOrder = (param) => request(`/charge/create/order`, 'POST', param);

/**提现下单 */
export const drawOrder = (param) => request(`/withdraw/create/order`, 'POST', param);

/**充值记录 */
export const rechargeRecord = (param) => request(`/game/rechargeRecord`, 'POST', param);

/**提现记录 */
export const withdrawRecord = (param) => request(`/game/withdrawRecord`, 'POST', param);

/**提现限制相关问题 */
export const withdrawInfoLimite = (param) => request(`/v1/green/bus/withdrawInfo`, 'POST', param);

/**邀请任务奖励汇总 */
export const withdrawStatInviteInfo = (param) => request(`/v1/green/bus/statInviteInfo`, 'POST', param);

/**获取分享链接 */
export const getShareLink = (param) => request(`/v1/green/bus/getUserShareUrlAndExtensionCode`, 'POST', param);

/**获取客服链接 */
export const getCustomerUrl = (param) => request(`/v1/green/bus/getCustomerUrl`, 'POST', param);

/**获取新手任务 */
export const withdrawTaskOne = (param) => request(`/v1/green/bus/taskOne`, 'POST', param);

/**领取新手任务奖励 */
export const withdrawTaskOneGot = (param) => request(`/v1/green/bus/taskOneGot`, 'POST', param);

/**获取日常任务进度 */
export const withdrawTaskProcess = (param) => request(`/v1/green/bus/getTaskProcess`, 'POST', param);

/**获取签到页面信息 */
export const getCheckInfo = (param) => request(`/activity/getSignInfo`, 'POST', param);

/**领取签到奖励 */
export const getCheck = (param) => request(`/activity/drawSignReward`, 'POST', param);

/**领取邀请奖励 */
export const withdrawReceiveInvite = (param) => request(`/v1/green/bus/receiveInviteRewards`, 'POST', param);
